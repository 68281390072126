<template>
  <div class="EditLocation">
    <div class="modal-wrapper">
        <div class="modal-title">
            <h2>Редагувати</h2>
            <div @click="Close" class="modal-close"></div>
        </div>
        <div class="form w-form">
            <div id="wf-form-Field">
                <div class="input-wrapper">
                    <label class="field-label">Назва розділу</label>
                    <input v-model="Name" type="text" class="text-field w-input"/>
                </div>
                <div class="input-wrapper">
                    <label class="field-label">Посилання</label>
                    <input v-model="URL" type="text" class="text-field w-input"/>
                </div>
                <input @click="Save" type="button" value="Зберегти" class="button w-button" />
                <input @click="Delete" type="button" value="Видалити" class="button delete w-button"/>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import firebase from 'firebase/compat/app';

export default {
    props:{
        Location: Object
    },
    data(){
        return{
            Name: '',
            URL: '',
        }
    },
    mounted(){
        this.Name = this.Location.name;
        this.URL = this.Location.url;
    },
    methods:{
        Close: function(){
            this.$emit('Close')
        },
        Save: function(){
            if(this.Name != ''){
                const db = firebase.database();

                const tempLocations = db.ref('Locations');
                const tempLocation = tempLocations.child(this.Location.id);

                tempLocation.update({
                    name: this.Name,
                    url: this.URL
                });

                this.Close();
            }
        },
        Delete: function(){
            const db = firebase.database();

            const tempLocations = db.ref('Locations');
            tempLocations.child(this.Location.id).set(null);

            this.Close();
        }
    }
}
</script>

<style scoped>

.EditLocation{
    position: absolute;

    top: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;

    background-color: rgba(0, 0, 0, 0.7);
}

.EditLocation .modal-wrapper{
    width: 560px
}

</style>