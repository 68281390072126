<template>
  <div class="AddNewCategory">
    <div class="modal-wrapper">
        <div class="modal-title">
            <h2>Додати</h2>
            <div @click="Close" class="modal-close"></div>
        </div>
        <div class="form w-form">
            <div id="wf-form-Field">
                <div class="radio-button-wrapper">
                    <div class="radio-button-item">
                        <label class="radio-button-field w-radio" @click="ClickUnder">
                            <input v-model="UnderCheck" v-bind:value="true" type="radio" class="w-form-formradioinput radio-button w-radio-input" />
                            <span class="radio-button-text w-form-label">Опис під ціною</span>
                        </label>
                    </div>
                    <div class="radio-button-item">
                        <label class="radio-button-field w-radio" @click="ClickLeft">
                            <input v-model="LeftCheck" v-bind:value="true" type="radio" class="w-form-formradioinput radio-button w-radio-input" />
                            <span class="radio-button-text w-form-label">Опис зліва від ціни</span>
                        </label>
                    </div>
                </div>
                <div class="input-wrapper">
                    <label class="field-label">Назва розділу</label>
                    <input v-model="Name" type="text" class="text-field w-input"/>
                </div>
                <input @click="Add" type="button" value="Додати" class="button w-button" />
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import { uuid } from 'vue-uuid';
import firebase from 'firebase/compat/app';

export default {
    props:{
        Location: Object,
        Categories: Array
    },
    data(){
        return{
            Name: '',

            UnderCheck: true,
            LeftCheck: false
        }
    },
    methods:{
        Close: function(){
            this.$emit('Close')
        },
        Add: function(){
            if(this.Name != ''){
                const db = firebase.database();

                const Categories = db.ref('Category');

                var tempId = uuid.v1();

                Categories.child(tempId).set({
                    id: tempId,

                    name: this.Name,

                    position: this.UnderCheck == true ? 'under' : this.LeftCheck == true ? 'left' : null,

                    locationId: this.Location.id,

                    order: this.Categories != undefined ? this.Categories.length : 0,
                    
                    visibility: true
                });

                this.Close();
            }
        },
        ClickUnder: function(){
            this.UnderCheck = true;
            this.LeftCheck = false;
        },
        ClickLeft: function(){
            this.UnderCheck = false;
            this.LeftCheck = true;
        }
    }
}
</script>

<style scoped>

.AddNewCategory{
    position: absolute;

    top: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;

    background-color: rgba(0, 0, 0, 0.7);
}

.AddNewCategory .modal-wrapper{
    width: 560px
}

</style>