<template>
  <div class="ChangeLocation">
    <div class="modal-wrapper">
        <div class="modal-title">
            <h2>Оберіть локацію</h2>
            <div @click="Close" class="modal-close"></div>
        </div>
        <div class="modal-location-wrapper">
            <div class="modal-location-item" v-for="Location of Locations" :key="Location.id">
                <div @click="ChangeLocation(Location)" class="location-button w-inline-block">
                    <div>{{Location.name}}</div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    props:{
        Locations: Array,
    },
    methods:{
        Close: function(){
            this.$emit("Close")
        },
        ChangeLocation: function(Location){
            this.$emit("ChangingLocation", Location)
            this.$emit("Close")
        }
    }
}
</script>

<style scoped>

.ChangeLocation{
    position: absolute;

    top: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;

    background-color: rgba(0, 0, 0, 0.7);

    z-index: 2;
}

.ChangeLocation .modal-wrapper{
    width: 560px
}


</style>