import { createRouter, createWebHistory } from "vue-router";

import AdminPage from "@/pages/Admin/AdminPage.vue"
import LoginPage from "@/pages/Admin/LoginPage.vue"
import EditPage from "@/pages/Admin/EditPage.vue"

import LocationPage from "@/pages/Admin/EditPanels/LocationPage.vue"
import CategoryPage from "@/pages/Admin/EditPanels/CategoryPage.vue"
import ItemsPage from "@/pages/Admin/EditPanels/ItemsPage.vue"

import MenuPage from "@/pages/Menu/MenuPage.vue"

export default createRouter({
    mode: "hash",
    base: process.env.VUE_ROUTER_BASE,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
          return savedPosition;
        } else {
          return { x: 0, y: 0 };
        }
    },
    history: createWebHistory(),
    routes: [
      { 
        name: 'AdminPage', 
        path: '/', 
        redirect: 'admin',
        component: AdminPage,
        children: [
          { name: 'LoginPage', path: '/login', component: LoginPage },
          { 
            name: 'EditPage', 
            path: '/admin', 
            redirect: 'admin/items',
            component: EditPage,
            children: [         
              { name: 'LocationPage', path: '/admin/locations', component: LocationPage },
              { name: 'CategoryPage', path: '/admin/categories', component: CategoryPage },
              { name: 'ItemsPage', path: '/admin/items', component: ItemsPage },
            ]
          },
        ]
      },
      { 
        name: 'MenuPage', 
        path: '/menu/:name', 
        component: MenuPage
      }
    ]
})