<template>
  <div class="CategoryPage">
    <div class="section wf-section">
        <div class="container">
            <div class="title-wrapper">
                <div class="page-title">
                    <h1>Розділи</h1>
                </div>
                <div @click="AddNewCategory" class="button w-inline-block">
                    <div class="button-icon"></div>
                    <div>Додати</div>
                </div>
            </div>
            <div class="table-wrapper">
                <div class="table-actions">
                    <div class="table-search-wrapper w-form">
                        <div class="filter">
                            <input v-model="FilterName" type="text" class="search-field w-input" placeholder="Пошук..."/>
                        </div>
                    </div>
                </div>
                <div class="w-layout-grid table-header">
                    <div class="table-header-item">
                        <div>Назва</div>
                    </div>
                    <div class="table-header-item">
                        <div>Положення опису</div>
                    </div>
                    <div class="table-header-item">
                        <div>Видимість</div>
                    </div>
                </div>
                <draggable v-model="FilterCategories" @change="OrderCategories" item-key="id">
                  <template #item="{element}">
                    <div class="table-row-link w-inline-block">
                        <div class="w-layout-grid table-row">
                            <div class="table-name">
                                <div>{{element.name}}</div>
                            </div>
                            <div class="table-category">
                                <div class="table-category-lable">{{element.position == 'left' ? 'Зліва від ціни' : element.position == 'under' ? 'Під ціною' : ''}}</div>
                            </div>
                            <div class="table-toggle">
                                <div class="toggle-wrapper">
                                    <div @click="ChangeVisibility(element)" class="toggle-background" :class="{ 'ActiveCheck': element.visibility }">
                                        <div class="toggle-switch"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="table-action">
                                <div @click="EditCategory(element)" class="table-edit-button"></div>
                            </div>
                        </div>
                    </div>
                  </template>
                </draggable>
            </div>
        </div>
    </div>
    <AddCategory v-on:Close="AddNewClose" :Location="Location" :Categories="Categories" v-if="AddNewCheck"/>
    <EditCategory v-on:Close="EditClose" :Category="EditingCategory" :Location="Location" v-if="EditCheck"/>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import firebase from 'firebase/compat/app';
import AddCategory from '@/components/Admin/Category/AddCategory.vue'
import EditCategory from '@/components/Admin/Category/EditCategory.vue'

export default {
  components:{
    draggable,
    AddCategory,
    EditCategory
  },
  props:{
    Location: Object
  },
  data(){
    return{
      Categories: [],

      FilterCategories: [],

      FilterName: "",

      AddNewCheck: false,
      EditCheck: false,

      EditingCategory: null
    }
  },
  created(){
    this.GetCategories();
  },
  methods:{
    ChangeVisibility: function(Category){
      Category.visibility = !Category.visibility

      const db = firebase.database();

      const tempCategories = db.ref('Category');
      const tempCategory = tempCategories.child(Category.id);

      tempCategory.update({
        'visibility': Category.visibility
      });
    },
    GetCategories: function(){
      const db = firebase.database();

      const Items = db.ref('Category');
      
      Items.on('value', (Item) => {
        this.Categories = []
        this.FilterCategories = []

        var tempItems = Item.val()

        for (const [value] of Object.entries(tempItems)) {
          const Values = db.ref('Category/' + value);
          Values.on('value', (Value) => {
            if(Value.val() != undefined && Value.val().id != undefined){
              if(Value.val().locationId == this.Location.id){
                if(this.Categories.find(x=>x.id == Value.val().id) == undefined)
                  this.Categories.push(Value.val())
                else
                  this.Categories[this.Categories.findIndex(x=>x.id == Value.val().id)] = Value.val()
              }
            }
          }); 
        }
      });

      this.SortCategories();
    },
    SortCategories: function(){
      this.Categories = this.Categories.sort(function(a, b) {
        if ( a.order < b.order ){
          return -1;
        }
        if ( a.order > b.order ){
          return 1;
        }
        return 0;
      });

      if(this.FilterName != ""){

        this.$nextTick(() => {
          this.FilterCategories = this.Categories.filter((item) => {
            if(item.name.toLowerCase().indexOf(this.FilterName.toLowerCase()) != -1){
              return true;
            }

            return false
          })
        })
      }
      else{
        this.FilterCategories = this.Categories
      }
    },
    OrderCategories: function(evt){
      var number = 0;

      this.FilterCategories.filter(x=>x.locationId == evt.moved.element.locationId).forEach(Category => {
        const db = firebase.database();

        const tempCategories = db.ref('Category');
        const tempCategory = tempCategories.child(Category.id);

        tempCategory.update({
          'order': number
        });

        number++;
      });
    },
    AddNewCategory: function(){
      this.AddNewCheck = true;
    },
    AddNewClose: function(){
      this.AddNewCheck = false;
    },
    EditCategory: function(Category){
      this.EditingCategory = Category
      this.EditCheck = true;
    },
    EditClose: function(){
      this.EditCheck = false;
      this.EditingCategory = null
    }
  },
  watch:{
    'FilterName': function(){
      if(this.FilterName != ""){    
        this.$nextTick(() => {
          this.FilterCategories = this.Categories.filter((item) => {
            if(item.name.toLowerCase().indexOf(this.FilterName.toLowerCase()) != -1){
              return true;
            }

            return false
          })
        })
      }
      else{
        this.FilterCategories = this.Categories;
      }
    },
    "Categories": { 
      handler: function () {      
        this.SortCategories();
      },
      deep: true
    }, 
    "Location": function(){
      this.AddNewCheck = false;
      this.EditCheck = false;
      this.EditingCategory = null;

      this.GetCategories();
    }
  }
}
</script>

<style scoped>

.CategoryPage{
  width: 100%;
  height: 100%;
}

.toggle-background{
  transition: 0.2s;

  cursor: pointer;
}

.ActiveCheck{
  background-color: rgb(52, 199, 89);

  transition: 0.2s;
}

.toggle-switch{
  transition: 0.2s;
}

.ActiveCheck .toggle-switch{
  transition: 0.2s;

  transform: translate3d(21px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;
}

.Empty{
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  background-color: rgba(0, 0, 0, 0.7);
  color: #FFFFFF;
}

</style>