import { createApp } from 'vue'
import router from '@/router'
import App from './App.vue'

import '@/webflow-files/styles.css'
import '@/webflow-files/menu.css'


import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/database';

const firebaseConfig = {
    apiKey: "AIzaSyAKv-Xn-Nix-2MmGrV-lVk1_YASa5ujjo4",
    authDomain: "coffe-12313.firebaseapp.com",
    databaseURL: "https://coffe-12313-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "coffe-12313",
    storageBucket: "coffe-12313.appspot.com",
    messagingSenderId: "984979110327",
    appId: "1:984979110327:web:89be88258a644b51b69032",
    measurementId: "G-4MQPCYGFL1"
};

firebase.initializeApp(firebaseConfig)

router.beforeEach((to, from, next) => {
    if(to.matched[0] != undefined && to.matched[0].name == "AdminPage" && to.name != "LoginPage"){   
        firebase.auth().onAuthStateChanged(function(user) {
            if (user) {            
                next();
            }
            else{
                router.push({ name: 'LoginPage'});
            }
          });   
    }
    else{
        next();
    }
})

let app;

firebase.auth().onAuthStateChanged(() => { 
    if(!app){
        app = createApp(App);
        app.config.globalProperties.$api_url = "https://coffe-12313-default-rtdb.europe-west1.firebasedatabase.app/"
        app.use(router);
        app.mount('#app');
    }
})
