<template>
  <router-view></router-view>
</template>

<script>
export default {
  mounted(){
    var body = document.getElementsByTagName("body")

    body[0].style.backgroundColor = "#FFFFFF"
  },
}
</script>

<style>

</style>