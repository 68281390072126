<template>
  <div class="EditItem">
    <div class="modal-wrapper">
        <div class="modal-title">
            <h2>Редагувати</h2>
            <div @click="Close" class="modal-close"></div>
        </div>
        <div class="form w-form">
            <div id="wf-form-Field">
                <div class="input-wrapper">
                    <label class="field-label">Розділ</label>
                    <select v-model="CategoryId" class="text-field selected w-select">
                        <option :value="Category.id" v-for="Category of Categories" :key="Category.id">{{Category.name}}</option>
                    </select>
                </div>
                <div class="input-wrapper">
                    <label class="field-label">Назва позиції</label>
                    <input v-model="Name" type="text" class="text-field w-input"/>
                </div>
                <div class="input-wrapper">
                    <label class="field-label">Опис позиції</label>
                    <input v-model="Description" type="text" class="text-field w-input"/>
                </div>
                <div class="form-price">
                    <div class="input-wrapper">
                        <label class="field-label">Ціна №1</label>
                        <input v-model="Price1" type="number" class="text-field w-input"/>
                    </div>
                    <div class="input-wrapper">
                        <label class="field-label">Опис ціни №1</label>
                        <input v-model="PriceDescription1" type="text" class="text-field w-input"/>
                    </div>
                    <div class="input-wrapper">
                        <label class="field-label">Ціна №2</label>
                        <input v-model="Price2" type="number" class="text-field w-input"/>
                    </div>
                    <div class="input-wrapper">
                        <label class="field-label">Опис ціни №2</label>
                        <input v-model="PriceDescription2" type="text" class="text-field w-input"/>
                    </div>
                    <div class="input-wrapper">
                        <label class="field-label">Ціна №3</label>
                        <input v-model="Price3" type="number" class="text-field w-input"/>
                    </div>
                    <div class="input-wrapper">
                        <label class="field-label">Опис ціни №3</label>
                        <input v-model="PriceDescription3" type="text" class="text-field w-input"/>
                    </div>    
                </div>
                <input @click="Save" type="button" value="Зберегти" class="button w-button" />
                <input @click="Delete" type="button" value="Видалити" class="button delete w-button"/>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import firebase from 'firebase/compat/app';

export default {
    props:{
        Categories: Array,
        Item: Object
    },
    data(){
        return{
            Name: '',
            Description: '',

            CategoryId: '',

            Price1: '',
            Price2: '',
            Price3: '',

            PriceDescription1: '',
            PriceDescription2: '',
            PriceDescription3: '',
        }
    },
    mounted(){
        this.Name = this.Item.name;
        this.CategoryId = this.Item.categoryId;

        console.log(this.Item.price3)

        if(this.Item.description != undefined){
            this.Description = this.Item.description
        }

        if(this.Item.price1 != undefined){
            this.Price1 = this.Item.price1
        }
        if(this.Item.price2 != undefined){
            this.Price2 = this.Item.price2
        }
        if(this.Item.price3 != undefined){
            this.Price3 = this.Item.price3
        }

        if(this.Item.priceDescription1 != undefined){
            this.PriceDescription1 = this.Item.priceDescription1
        }
        if(this.Item.priceDescription2 != undefined){
            this.PriceDescription2 = this.Item.priceDescription2
        }
        if(this.Item.priceDescription3 != undefined){
            this.PriceDescription3 = this.Item.priceDescription3
        }
    },
    methods:{
        Close: function(){
            this.$emit('Close')
        },
        Save: function(){
            if(this.Name != ''){
                const db = firebase.database();

                const tempItems = db.ref('Items');
                const tempItem = tempItems.child(this.Item.id);

                tempItem.update({
                    name: this.Name,
                    description: this.Description != '' ? this.Description : null,

                    categoryId: this.CategoryId,

                    price1: this.Price1 != '' ? this.Price1 : null,
                    price2: this.Price2 != '' ? this.Price2 : null,
                    price3: this.Price3 != '' ? this.Price3 : null,

                    priceDescription1: this.PriceDescription1 != '' ? this.PriceDescription1 : null,
                    priceDescription2: this.PriceDescription2 != '' ? this.PriceDescription2 : null,
                    priceDescription3: this.PriceDescription3 != '' ? this.PriceDescription3 : null,
                });

                this.Close();
            }
        },
        Delete: function(){
            const db = firebase.database();

            const tempItems = db.ref('Items');
            tempItems.child(this.Item.id).set(null);

            this.Close();
        }
    }
}
</script>

<style scoped>

.EditItem{
    position: absolute;

    top: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;

    background-color: rgba(0, 0, 0, 0.7);
}

.EditItem .modal-wrapper{
    width: 560px
}

</style>