<template>
  <div class="MenuPage">
    <transition-group @before-enter="onBeforeEnter" @enter="onEnter" name="list">
      <div class="menu-section wf-section" v-for="Category of Categories.filter(x=>x.visibility)" :key="Category.id">
          <div class="menu-container">
              <div class="menu-item-container">
                  <div class="header-section">
                      <div>{{Category.name}}</div>
                  </div>
                  <div class="menu-item" v-for="Item of Items.filter(x=>x.categoryId == Category.id && x.visibility)" :key="Item.id">
                      <div class="menu-item-name">
                          <div class="menu-name">
                              <div>{{Item.name}}</div>
                          </div>
                          <div class="menu-description" v-if="Item.description != undefined">
                              <div class="text-description">{{Item.description}}</div>
                          </div>
                      </div>
                      <div class="menu-price-box">
                          <div :class="{ 'menu-price-wrapper': Category.position == 'under', 'menu-price-type-2-wrapper': Category.position == 'left' }" v-if="Item.price1 != undefined">
                              <div class="menu-price">
                                  <div>{{Item.price1}}</div>
                              </div>
                              <div :class="{ 'menu-price-decription': Category.position == 'under', 'menu-price-decription-type-2': Category.position == 'left' }" v-if="Item.priceDescription1 != undefined">
                                  <div class="text-description">{{Item.priceDescription1}}</div>
                              </div>
                          </div>
                          <div :class="{ 'menu-price-wrapper': Category.position == 'under', 'menu-price-type-2-wrapper': Category.position == 'left' }" v-if="Category.position == 'under' && Item.price2 != undefined">
                              <div class="menu-price">
                                  <div>{{Item.price2}}</div>
                              </div>
                              <div :class="{ 'menu-price-decription': Category.position == 'under', 'menu-price-decription-type-2': Category.position == 'left' }" v-if="Item.priceDescription2 != undefined">
                                  <div class="text-description">{{Item.priceDescription2}}</div>
                              </div>
                          </div>
                          <div :class="{ 'menu-price-wrapper': Category.position == 'under', 'menu-price-type-2-wrapper': Category.position == 'left' }" v-if="Category.position == 'under' && Item.price3 != undefined">
                              <div class="menu-price">
                                  <div>{{Item.price3}}</div>
                              </div>
                              <div :class="{ 'menu-price-decription': Category.position == 'under', 'menu-price-decription-type-2': Category.position == 'left' }" v-if="Item.priceDescription3 != undefined">
                                  <div class="text-description">{{Item.priceDescription3}}</div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    </transition-group>
    <a href="https://www.instagram.com/8.coffee_bar/" target="_blank" class="instagram-wrapper w-inline-block">
        <div>Наш Instagram</div>
        <div class="instagram-icon"></div>
    </a>
  </div>
</template>

<script>
import { gsap } from "gsap";
import firebase from 'firebase/compat/app';

export default {
  data(){
    return{
      Locations: [],
      Categories: [],
      Items: [],

      Location: null
    }
  },
  created(){
    this.GetLocations();
  },
  mounted(){
    var body = document.getElementsByTagName("body")

    body[0].style.backgroundColor = "#1b1b1b"
  },
  methods:{
    GetLocations: function(){
      const db = firebase.database();
      const ref = db.ref('Locations');

      ref.on('value', (Item) => {
          this.Locations = [];
          
          var tempItems = Item.val()

          for (const [value] of Object.entries(tempItems)) {
              const Values = db.ref('Locations/' + value);
              Values.on('value', (Value) => {
                  if(Value.val() != undefined && Value.val().id != undefined){
                      if(this.Locations.find(x=>x.id == Value.val().id) == undefined)
                          this.Locations.push(Value.val())
                      else
                          this.Locations[this.Locations.findIndex(x=>x.id == Value.val().id)] = Value.val()
                  }
              }); 
          }

          this.Location = this.Locations.find(x=>x.url.indexOf(this.$route.params.name) != -1);
      });
    },
    GetCategories: function(){
      const db = firebase.database();

      const Items = db.ref('Category');
      
      Items.on('value', (Item) => {
        this.Categories = []
        this.FilterCategories = []

        var tempItems = Item.val()

        for (const [value] of Object.entries(tempItems)) {
          const Values = db.ref('Category/' + value);
          Values.on('value', (Value) => {
            if(Value.val() != undefined && Value.val().id != undefined && this.Location != null){
              if(Value.val().locationId == this.Location.id){
                if(this.Categories.find(x=>x.id == Value.val().id) == undefined)
                  this.Categories.push(Value.val())
                else
                  this.Categories[this.Categories.findIndex(x=>x.id == Value.val().id)] = Value.val()
              }
            }
          }); 
        }
      });
    },
    GetItems: function(){
      const db = firebase.database();

      const Items = db.ref('Items');
      
      Items.on('value', (Item) => {
        this.Items = []
        this.FilterItems = []
        var tempItems = Item.val()

        for (const [value] of Object.entries(tempItems)) {
          const Values = db.ref('Items/' + value);
          Values.on('value', (Value) => {
            if(Value.val() != undefined && Value.val().id != undefined){
              if(this.Categories.find(x=>x.id == Value.val().categoryId) != undefined){
                if(this.Items.find(x=>x.id == Value.val().id) == undefined)
                  this.Items.push(Value.val())
                else
                  this.Items[this.Items.findIndex(x=>x.id == Value.val().id)] = Value.val()
              }
            }
          }); 
        }
      });
    },
    SortCategories: function(){
      this.Categories = this.Categories.sort(function(a, b) {
        if ( a.order < b.order ){
          return -1;
        }
        if ( a.order > b.order ){
          return 1;
        }
        return 0;
      });
    },
    SortItems: function(){
      this.Items = this.Items.sort(function(a, b) {      
        if ( a.order < b.order ){
          return -1;
        }
        if ( a.order > b.order ){
          return 1;
        }
        return 0;
      });
    },
    onBeforeEnter(el) {
      el.style.opacity = 0
      el.style.transform = 'translate3d(0px, 70px, 0px)'
    },
    onEnter(el, done) {
      gsap.to(el, {
        opacity: 1,
        transform: 'translate3d(0px, 00px, 0px)',
        delay: el.dataset.index * 0.15,
        onComplete: done
      })
    },
  },
  watch:{
    "Location": function(){
      this.GetCategories();
    },
    "Items": { 
      handler: function () {   
        this.SortItems();
      },
      deep: true
    }, 
    "Categories": { 
      handler: function () {     
        this.GetItems(); 
        this.SortCategories();
      },
      deep: true
    }, 
  }
}
</script>

<style scoped>

.MenuPage{
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;

  width: 100%;
  height: 100%;

  background-color: #1b1b1b;
}

.MenuPage .menu-section{
  width: 100%;
}

</style>