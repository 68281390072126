<template>
  <div class="AddNewLocation">
    <div class="modal-wrapper">
        <div class="modal-title">
            <h2>Додати</h2>
            <div @click="Close" class="modal-close"></div>
        </div>
        <div class="form w-form">
            <div id="wf-form-Field">
                <div class="input-wrapper">
                    <label class="field-label">Назва розділу</label>
                    <input v-model="Name" type="text" class="text-field w-input"/>
                </div>
                <div class="input-wrapper">
                    <label class="field-label">Посилання</label>
                    <input v-model="URL" type="text" class="text-field w-input"/>
                </div>
                <input @click="Add" type="button" value="Додати" class="button w-button" />
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import { uuid } from 'vue-uuid';
import firebase from 'firebase/compat/app';

export default {
    props:{
        Locations: Array
    },
    data(){
        return{
            Name: '',
            URL: ''
        }
    },
    methods:{
        Close: function(){
            this.$emit('Close')
        },
        Add: function(){
            if(this.Name != ''){
                const db = firebase.database();

                const Locations = db.ref('Locations');

                var tempId = uuid.v1();

                Locations.child(tempId).set({
                    id: tempId,

                    name: this.Name,
                    
                    url: this.URL,
                    
                    visibility: true
                });

                this.Close();
            }
        }
    }
}
</script>

<style scoped>

.AddNewLocation{
    position: absolute;

    top: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;

    background-color: rgba(0, 0, 0, 0.7);
}

.AddNewLocation .modal-wrapper{
    width: 560px
}

</style>