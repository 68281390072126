<template>
  <div class="LoginPage">
    <div class="modal-wrapper SignIn">
        <div class="modal-title">
            <h2>Sign in</h2>
        </div>
        <div class="form w-form">
            <div id="wf-form-Field" name="wf-form-Field">
                <div class="input-wrapper">
                  <label class="field-label">Login</label>
                  <input v-model="Login" type="email" class="text-field w-input" />
                </div>
                <div class="input-wrapper">
                  <label class="field-label">Password</label>
                  <input v-model="Password" type="password" class="text-field w-input" />
                  </div>
                <input @click="LogIn" type="button" value="Sign in" class="button w-button"/>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import firebase from 'firebase/compat/app';

export default {
  data(){
    return{
      Login: "",
      Password: ""
    }
  },
  methods:{
    LogIn: async function(){
      try {
        firebase.auth().signInWithEmailAndPassword(this.Login, this.Password)
        this.$router.push({ name: 'EditPage'});
      } catch (error) {
        console.log(error)
      }
    }
  }

}
</script>

<style scoped>

.LoginPage{
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
}

.SignIn{
  width: 450px;
}

</style>