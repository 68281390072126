<template>
  <div class="navbar w-nav">
        <div class="location-choose-wrapper">
            <div @click="ChangeLocation" class="location-wrapper">
                <img src="https://uploads-ssl.webflow.com/632991f19e1976821fd86dc7/632adf7d36cf706bcf236cb9_building-store%201.svg" loading="lazy" alt="" class="location" />
                <div>{{Location.name}}</div>
            </div>
        </div>
        <div class="navbar-container">
            <nav role="navigation" class="navmenu w-nav-menu">
                <div class="navigation-links-wrapper">
                    <div @click="OpenPage(Item)" class="navlink w-inline-block" :class="{ 'w--current': Item.active }" v-for="Item of Menu" :key="Item.id">
                        <div>{{Item.name}}</div>
                    </div>
                </div>
            </nav>
            <div class="menu-button w-nav-button">
                <div class="w-icon-nav-menu"></div>
            </div>
        </div>
    </div>
    <ChangeLocation v-on:ChangingLocation="ChangingLocation" v-on:Close="CloseLocation" :Locations="Locations" v-if="OpenLocation"/>
</template>

<script>
import ChangeLocation from '@/components/Admin/ChangeLocation.vue'

export default {
    components:{
        ChangeLocation
    },
    props:{
        Location: Object,
        Locations: Array,
    },
    data(){
        return{
            OpenLocation: false,

            Menu: [ { id: 1, name: 'Меню', active: true, page: "ItemsPage" }, { id: 2, name: 'Розділи', active: false, page: "CategoryPage" }, { id: 3, name: 'Локації', active: false, page: "LocationPage" }  ]       
        }
    },
    created(){
        if(localStorage.saveMenuItem){
            var MenuItem = JSON.parse(localStorage.getItem("saveMenuItem") || '[]');
                
            this.Menu.forEach(Item => {
                if(MenuItem.id == Item.id){
                    Item.active = true;
                }
                else{
                    Item.active = false;
                }
            });

            this.$router.push({ name: MenuItem.page });
        }
    },
    methods:{
        OpenPage: function(MenuItem){
            this.Menu.forEach(Item => {
                Item.active = false;
            });

            MenuItem.active = true;

            localStorage.saveMenuItem = JSON.stringify(MenuItem);

            this.$router.push({ name: MenuItem.page });
        },
        ChangeLocation: function(){
            this.OpenLocation = true;
        },
        CloseLocation: function(){
            this.OpenLocation = false;
        },
        ChangingLocation: function(Location){
           this.$emit("ChangingLocation", Location)
        }
    }

}
</script>

<style>

</style>