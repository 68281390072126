<template>
  <div class="ItemsPage">
    <div class="section wf-section" v-if="Categories.length > 0">
        <div class="container">
            <div class="title-wrapper">
                <div class="page-title">
                    <h1>Меню</h1>
                </div>
                <div @click="AddNewItem" class="button w-inline-block">
                    <div class="button-icon"></div>
                    <div>Додати</div>
                </div>
            </div>
            <div class="table-wrapper">
                <div class="table-actions">
                    <div class="table-search-wrapper w-form">
                        <div class="filter">
                            <input v-model="FilterName" type="text" class="search-field w-input" placeholder="Пошук..."/>
                        </div>
                    </div>
                    <div class="table-filter-wrapper">
                        <select v-model="FilterCategoryId" class="text-field selected w-select">
                            <option value="">Розділ...</option>
                            <option :value="Category.id" v-for="Category of Categories" :key="Category.id">{{Category.name}}</option>
                        </select>
                    </div>
                </div>
                <div class="w-layout-grid table-header">
                    <div class="table-header-item">
                        <div>Назва</div>
                    </div>
                    <div class="table-header-item">
                        <div>Розділ</div>
                    </div>
                    <div class="table-header-item">
                        <div>Видимість</div>
                    </div>
                </div>
                <draggable v-model="FilterItems" @change="OrderItems" item-key="id">
                  <template #item="{element}">
                    <div class="table-row-link w-inline-block">
                        <div class="w-layout-grid table-row">
                            <div class="table-name">
                                <div>{{element.name}}</div>
                            </div>
                            <div class="table-category">
                                <div class="table-category-lable">{{element.category.name}}</div>
                            </div>
                            <div class="table-toggle">
                                <div class="toggle-wrapper">
                                    <div @click="ChangeVisibility(element)" class="toggle-background" :class="{ 'ActiveCheck': element.visibility }">
                                        <div class="toggle-switch"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="table-action">
                                <div @click="EditItem(element)" class="table-edit-button"></div>
                            </div>
                        </div>
                    </div>
                  </template>
                </draggable>
            </div>
        </div>
    </div>
    <div class="Empty" v-else>
      <span>Спочатку додайте розділи</span>
    </div>
    <AddItem v-on:Close="AddNewClose" :Items="Items" :Categories="Categories" v-if="AddNewCheck"/>
    <EditItem v-on:Close="EditClose" :Item="EditingItem" :Categories="Categories" v-if="EditCheck"/>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import firebase from 'firebase/compat/app';
import AddItem from '@/components/Admin/Items/AddItem.vue'
import EditItem from '@/components/Admin/Items/EditItem.vue'

export default {
  components:{
    draggable,
    AddItem,
    EditItem
  },
  props:{
    Location: Object
  },
  data(){
    return{
      Items: [],
      Categories: [],

      FilterItems: [],

      FilterName: "",
      FilterCategoryId: "",

      AddNewCheck: false,
      EditCheck: false,

      EditingItem: null
    }
  },
  created(){
    this.GetCategories();
    this.GetItems();
  },
  methods:{
    ChangeVisibility: function(Item){
      Item.visibility = !Item.visibility

      const db = firebase.database();

      const tempItems = db.ref('Items');
      const tempItem = tempItems.child(Item.id);

      tempItem.update({
        'visibility': Item.visibility
      });
    },
    GetItems: function(){
      const db = firebase.database();

      const Items = db.ref('Items');
      
      Items.on('value', (Item) => {
        this.Items = []
        this.FilterItems = []
        var tempItems = Item.val()

        for (const [value] of Object.entries(tempItems)) {
          const Values = db.ref('Items/' + value);
          Values.on('value', (Value) => {
            if(Value.val() != undefined && Value.val().id != undefined){
              if(this.Categories.find(x=>x.id == Value.val().categoryId) != undefined){
                if(this.Items.find(x=>x.id == Value.val().id) == undefined)
                  this.Items.push(Value.val())
                else
                  this.Items[this.Items.findIndex(x=>x.id == Value.val().id)] = Value.val()
              }
            }
          }); 
        }
      });

      this.SyncItemCategory();
    },
    GetCategories: function(){
      const db = firebase.database();

      const Items = db.ref('Category');
      
      Items.on('value', (Item) => {
        this.Categories = []
        var tempItems = Item.val()

        for (const [value] of Object.entries(tempItems)) {
          const Values = db.ref('Category/' + value);
          Values.on('value', (Value) => {
            if(Value.val() != undefined && Value.val().id != undefined){
              if(Value.val().locationId == this.Location.id){
                if(this.Categories.find(x=>x.id == Value.val().id) == undefined)
                  this.Categories.push(Value.val())
                else
                  this.Categories[this.Categories.findIndex(x=>x.id == Value.val().id)] = Value.val()
              }
            }
          }); 
        }
      });
    },
    SyncItemCategory: function(){
      if(this.Categories.length > 0){
        this.Items.forEach(item => {
          if(this.Categories.find(x=>x.id == item.categoryId) != undefined)
            item.category = this.Categories.find(x=>x.id == item.categoryId)
        });

        this.Categories = this.Categories.sort(function(a, b) {
          if ( a.order < b.order ){
            return -1;
          }
          if ( a.order > b.order ){
            return 1;
          }
          return 0;
        });

        this.Items = this.Items.sort(function(a, b) {
          if ( a.category.order < b.category.order ){
            return -1;
          }
          else if ( a.category.order > b.category.order ){
            return 1;
          }
          else{
            if ( a.order < b.order ){
              return -1;
            }
            if ( a.order > b.order ){
              return 1;
            }
            return 0;
          }
        });

        if(this.FilterName != ""){
        
          this.FilterCategoryId = "";

          this.$nextTick(() => {
            this.FilterItems = this.Items.filter((item) => {
              if(item.name.toLowerCase().indexOf(this.FilterName.toLowerCase()) != -1){
                return true;
              }

              return false
            })
          })
        }
        else if(this.FilterCategoryId != ""){   
          this.FilterName = "";

          this.$nextTick(() => {
            if(this.FilterCategoryId != ""){
              this.FilterItems = this.Items.filter(x=>x.categoryId == this.FilterCategoryId);
            }     
          })
        }
        else{
          this.FilterItems = this.Items
        }
      }
    },
    OrderItems: function(evt){
      var number = 0;

      this.FilterItems.filter(x=>x.categoryId == evt.moved.element.categoryId).forEach(Item => {
        const db = firebase.database();

        const tempItems = db.ref('Items');
        const tempItem = tempItems.child(Item.id);

        tempItem.update({
          'order': number
        });

        number++;
      });
    },
    AddNewItem: function(){
      this.AddNewCheck = true;
    },
    AddNewClose: function(){
      this.AddNewCheck = false;
    },
    EditItem: function(Item){
      this.EditingItem = Item
      this.EditCheck = true;
    },
    EditClose: function(){
      this.EditCheck = false;
      this.EditingItem = null
    }
  },
  watch:{
    'FilterName': function(){
      if(this.FilterName != ""){
        
        this.FilterCategoryId = "";

        this.$nextTick(() => {
          this.FilterItems = this.Items.filter((item) => {
            if(item.name.toLowerCase().indexOf(this.FilterName.toLowerCase()) != -1){
              return true;
            }

            return false
          })
        })
      }
      else{
        if(this.FilterCategoryId == "")
            this.FilterItems = this.Items;
      }
    },
    "FilterCategoryId": function(){
      if(this.FilterCategoryId != ""){
        
        this.FilterName = "";

        this.$nextTick(() => {
          if(this.FilterCategoryId != ""){
            this.FilterItems = this.Items.filter(x=>x.categoryId == this.FilterCategoryId);
          }     
        })
      }
      else{
        if(this.FilterName == "")
          this.FilterItems = this.Items;
      }
    },
    "Items": { 
      handler: function () {   
        this.SyncItemCategory();
      },
      deep: true
    }, 
    "Categories": { 
      handler: function () {      
        this.SyncItemCategory();
      },
      deep: true
    }, 
    "Location": function(){
      this.AddNewCheck = false;
      this.EditCheck = false;
      this.EditingItem = null;

      this.GetCategories();
      this.GetItems();
    }
  }
}
</script>

<style scoped>

.ItemsPage{
  width: 100%;
  height: 100%;
}

.toggle-background{
  transition: 0.2s;

  cursor: pointer;
}

.ActiveCheck{
  background-color: rgb(52, 199, 89);

  transition: 0.2s;
}

.toggle-switch{
  transition: 0.2s;
}

.ActiveCheck .toggle-switch{
  transition: 0.2s;

  transform: translate3d(21px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;
}

.Empty{
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  background-color: rgba(0, 0, 0, 0.7);
  color: #FFFFFF;
}

</style>