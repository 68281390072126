<template>
  <div class="LocationPage">
    <div class="section wf-section">
        <div class="container">
            <div class="title-wrapper">
                <div class="page-title">
                    <h1>Локації</h1>
                </div>
                <div @click="AddNewLocation" class="button w-inline-block">
                    <div class="button-icon"></div>
                    <div>Додати</div>
                </div>
            </div>
            <div class="table-wrapper">
                <div class="table-actions">
                    <div class="table-search-wrapper w-form">
                        <div class="filter">
                            <input v-model="FilterName" type="text" class="search-field w-input" placeholder="Пошук..."/>
                        </div>
                    </div>
                </div>
                <div class="w-layout-grid table-header">
                    <div class="table-header-item">
                        <div>Назва</div>
                    </div>
                    <div class="table-header-item">
                        <div>Посилання</div>
                    </div>
                    <div class="table-header-item">
                        <div>Видимість</div>
                    </div>
                </div>
                <div class="table-row-link w-inline-block" v-for="Location of Locations" :key="Location.id">
                  <div class="w-layout-grid table-row">
                      <div class="table-name">
                          <div>{{Location.name}}</div>
                      </div>
                      <div class="table-category">
                          <div class="table-category-lable">{{Location.url}}</div>
                      </div>
                      <div class="table-toggle">
                          <div class="toggle-wrapper">
                              <div @click="ChangeVisibility(Location)" class="toggle-background" :class="{ 'ActiveCheck': Location.visibility }">
                                  <div class="toggle-switch"></div>
                              </div>
                          </div>
                      </div>
                      <div class="table-action">
                          <div @click="EditLocation(Location)" class="table-edit-button"></div>
                      </div>
                  </div>
                </div>
            </div>
        </div>
    </div>
    <AddLocation v-on:Close="AddNewClose" :Locations="Locations" v-if="AddNewCheck"/>
    <EditLocation v-on:Close="EditClose" :Location="EditingLocation" v-if="EditCheck"/>
  </div>
</template>

<script>
import firebase from 'firebase/compat/app';
import AddLocation from '@/components/Admin/Location/AddLocation.vue'
import EditLocation from '@/components/Admin/Location/EditLocation.vue'

export default {
  components:{
    AddLocation,
    EditLocation
  },
  data(){
    return{
      Locations: [],

      FilterLocations: [],

      FilterName: "",

      AddNewCheck: false,
      EditCheck: false,

      EditingLocation: null
    }
  },
  created(){
    this.GetLocations();
  },
  methods:{
    ChangeVisibility: function(Location){
      Location.visibility = !Location.visibility

      const db = firebase.database();

      const tempLocations = db.ref('Locations');
      const tempLocation = tempLocations.child(Location.id);

      tempLocation.update({
        'visibility': Location.visibility
      });
    },
    GetLocations: function(){
      const db = firebase.database();

      const Locations = db.ref('Locations');
      
      Locations.on('value', (Item) => {
        this.Locations = []
        this.FilterLocations = []

        var tempLocations = Item.val()

        for (const [value] of Object.entries(tempLocations)) {
          const Values = db.ref('Locations/' + value);
          Values.on('value', (Value) => {
            if(Value.val() != undefined && Value.val().id != undefined){
              if(this.Locations.find(x=>x.id == Value.val().id) == undefined)
                this.Locations.push(Value.val())
              else
                this.Locations[this.Locations.findIndex(x=>x.id == Value.val().id)] = Value.val()
            }
          }); 
        }
      });

      this.SortLocations();
    },
    SortLocations: function(){
      this.Locations = this.Locations.sort(function(a, b) {
        if ( a.name < b.name ){
          return -1;
        }
        if ( a.name > b.name ){
          return 1;
        }
        return 0;
      });

      if(this.FilterName != ""){
        this.$nextTick(() => {
          this.FilterLocations = this.Locations.filter((item) => {
            if(item.name.toLowerCase().indexOf(this.FilterName.toLowerCase()) != -1){
              return true;
            }

            return false
          })
        })
      }
      else{
        this.FilterLocations = this.Locations
      }
    },
    AddNewLocation: function(){
      this.AddNewCheck = true;
    },
    AddNewClose: function(){
      this.AddNewCheck = false;
    },
    EditLocation: function(Locations){
      this.EditingLocation = Locations
      this.EditCheck = true;
    },
    EditClose: function(){
      this.EditCheck = false;
      this.EditingLocation = null
    }
  },
  watch:{
    'FilterName': function(){
      if(this.FilterName != ""){    
        this.$nextTick(() => {
          this.FilterCategories = this.Categories.filter((item) => {
            if(item.name.toLowerCase().indexOf(this.FilterName.toLowerCase()) != -1){
              return true;
            }

            return false
          })
        })
      }
      else{
        this.FilterCategories = this.Categories;
      }
    },
    "Locations": { 
      handler: function () {      
        this.SortLocations();
      },
      deep: true
    }
  }
}
</script>

<style scoped>

.LocationPage{
  width: 100%;
  height: 100%;
}

.toggle-background{
  transition: 0.2s;

  cursor: pointer;
}

.ActiveCheck{
  background-color: rgb(52, 199, 89);

  transition: 0.2s;
}

.toggle-switch{
  transition: 0.2s;
}

.ActiveCheck .toggle-switch{
  transition: 0.2s;

  transform: translate3d(21px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;
}

.Empty{
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  background-color: rgba(0, 0, 0, 0.7);
  color: #FFFFFF;
}

</style>