<template>
  <div class="EditPage">
    <NavMenu v-on:ChangingLocation="ChangingLocation" :Location="Location" :Locations="Locations"/>
    <router-view v-slot="{ Component }">
        <component :Location="Location" :is="Component"></component>
    </router-view>
  </div>
</template>

<script>
import firebase from 'firebase/compat/app';
import NavMenu from '@/components/Admin/NavMenu.vue'

export default {
    components:{
        NavMenu
    },
    data(){
        return{
            Location: {},
            Locations: []
        }
    },
    created(){
        const db = firebase.database();
        const ref = db.ref('Locations');

        ref.on('value', (Item) => {
            this.Locations = [];
            
            var tempItems = Item.val()

            for (const [value] of Object.entries(tempItems)) {
                const Values = db.ref('Locations/' + value);
                Values.on('value', (Value) => {
                    if(Value.val() != undefined && Value.val().id != undefined){
                        if(this.Locations.find(x=>x.id == Value.val().id) == undefined)
                            this.Locations.push(Value.val())
                        else
                            this.Locations[this.Locations.findIndex(x=>x.id == Value.val().id)] = Value.val()
                    }
                }); 
            }

            this.Location = this.Locations[0];
        });
    },
    methods:{
        ChangingLocation: function(Location){
            this.Location = Location;
        }
    }

}
</script>

<style scoped>
.EditPage{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    width: 100%;
    height: 100%;
}
</style>