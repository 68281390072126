<template>
  <router-view></router-view>
</template>

<script>

export default {
  name: 'App',
  components: {
  }
}
</script>

<style>

:root{
  width: 100%;
  height: 100%;
}

body{
  width: 100%;
  height: 100%;

  margin: 0;

  overflow-x: hidden;
}

#app {
  width: 100%;
  height: 100%;
}

.button{
  display: inline-flex !important;
}

.button, .navlink, .table-row-link{
  cursor: pointer;
}

.delete{
  margin-left: 12px !important;

  background-color: #fff !important;
  color: #e95149 !important;
}

.delete:hover{
  background-color: #e95149 !important;
  color: #fff !important;
}



</style>
